/*
  global
  window, document, $, Webflow
*/

(function () {
  // if the page is being rendered on the server, don't continue
  if (typeof window === 'undefined') return;

  function setAllBackgroundVideoStates(shouldPlay) {
    if (Webflow.env('design')) {
      return;
    }

    // 1. set every video
    $('video').each(function () {
      shouldPlay && $(this).prop('autoplay') ? this.play() : this.pause();
    });

    // 2. set every video controller button
    $('.w-background-video--control').each(function () {
      if (shouldPlay) {
        showPauseButton($(this));
      } else {
        showPlayButton($(this));
      }
    });
  }

  function showPlayButton($btn) {
    $btn.find('> span').each(function (i) {
      $(this).prop('hidden', () => i === 0);
    });
  }

  function showPauseButton($btn) {
    $btn.find('> span').each(function (i) {
      $(this).prop('hidden', () => i === 1);
    });
  }

  $(document).ready(() => {
    const watcher = window.matchMedia('(prefers-reduced-motion: reduce)');

    // respond to changing preferences
    watcher.addEventListener('change', (e) => {
      setAllBackgroundVideoStates(!e.matches);
    });

    if (watcher.matches) {
      // user currently prefers reduced motion, pause all immediately
      setAllBackgroundVideoStates(false);
    }

    // show play button for videos without autoplay
    $('video:not([autoplay])').each(function () {
      $(this)
        .parent()
        .find('.w-background-video--control')
        .each(function () {
          showPlayButton($(this));
        });
    });

    $(document).on('click', '.w-background-video--control', function (e) {
      if (Webflow.env('design')) return;

      const btn = $(e.currentTarget);
      const video = $(`video#${btn.attr('aria-controls')}`).get(0);
      if (!video) return;

      if (video.paused) {
        const play = video.play();
        showPauseButton(btn);

        // IE does not return a promise from .play()
        if (play && typeof play.catch === 'function') {
          play.catch(() => {
            // something went wrong and it's not playing
            showPlayButton(btn);
          });
        }
      } else {
        video.pause();
        showPlayButton(btn);
      }
    });
  });
})();
